"use client"

import { Typography } from "@/components/ui/data-display/Typography"
import { useTheme } from "@mui/material/styles"

import { Button } from "@/components/ui/inputs/Button"
import { Container } from "@/components/ui/layout/Container"
import useGetMailToSupport from "@/hooks/useGetMailToSupport"
import { useTranslation } from "@/i18n"
import authErrorImage from "@/public/images/AuthError.svg"
import authErrorThumb from "@/public/images/AuthErrorThumb.png"
import notFoundImage from "@/public/images/NotFound.svg"
import notFoundThumb from "@/public/images/NotFoundThumb.png"
import { type PostgrestError, useGetUserSessionData } from "@/utils/lib"
import { mdiRefresh, mdiSend } from "@mdi/js"
import Icon from "@mdi/react"
import { Stack } from "@mui/material"

import LazyImage from "../LazyImage"
import de from "./messages/de.json"

export interface UnexpectedErrorProps {
  title?: string
  message?: string
  variant?: "not-found" | "auth"
  error?: Error | PostgrestError
  reset?: () => void
}

export default function UnexpectedError({
  title,
  message,
  variant = "auth",
  reset,
}: UnexpectedErrorProps) {
  const theme = useTheme()
  const { t } = useTranslation(de)

  const { data: userData, isFetching: isFetchingUserData } =
    useGetUserSessionData()

  const { mailTo, isFetching: isFetchingSystemInfo } = useGetMailToSupport(
    userData?.email ?? "Unbekannt",
  )

  return (
    <Container>
      <Stack p={4} alignItems="center" spacing={1}>
        <Typography variant="h6">{title ?? t("title.label")}</Typography>
        <Typography color={theme.palette.text.disabled} variant="body1">
          {message ?? t("message.label")}
        </Typography>
        {variant === "auth" && (
          <LazyImage
            width={250}
            height={200}
            src={authErrorImage}
            thumbnail={authErrorThumb}
            alt={t("image.alt")}
          />
        )}
        {variant === "not-found" && (
          <LazyImage
            width={250}
            height={200}
            src={notFoundImage}
            thumbnail={notFoundThumb}
            alt={t("image.alt")}
          />
        )}
        <Stack direction="row" spacing={1}>
          <a href={mailTo} target="_blank" rel="noreferrer">
            <Button
              startIcon={<Icon path={mdiSend} size={0.75} />}
              variant="text"
              color="inherit"
              size="small"
              sx={{ color: theme.palette.action.active }}
              loading={isFetchingUserData || isFetchingSystemInfo}
            >
              {t("action.report.label")}
            </Button>
          </a>
          {reset && (
            <Button
              startIcon={<Icon path={mdiRefresh} size={0.75} />}
              variant="text"
              color="inherit"
              size="small"
              sx={{ color: theme.palette.action.active }}
              onClick={
                // Attempt to recover by trying to re-render the segment
                () => {
                  if (reset) reset()
                }
              }
            >
              {t("action.retry.label")}
            </Button>
          )}
        </Stack>
      </Stack>
    </Container>
  )
}
