"use client"
import { LoadingButton as MuiLoadingButton } from "@mui/lab"
import {
  type ButtonGroupProps,
  ButtonGroup as MuiButtonGroup,
  type ButtonProps,
} from "@mui/material"
import { type ReactNode, forwardRef } from "react"

/**
 * For any reason, the `LoadingButton` props cause a TS endless loop: https://github.com/vercel/next.js/issues/31994.
 * Therefore we have to use the `ButtonProps` type from `@mui/material` instead of `@mui/lab` and extend them manually.
 */
export type LoadingButtonProps = ButtonProps & {
  /**
   * If `true`, the loading indicator is shown and the button becomes disabled.
   * @default false
   */
  loading?: boolean
  /**
   * Element placed before the children if the button is in loading state.
   * The node should contain an element with `role="progressbar"` with an accessible name.
   * By default we render a `CircularProgress` that is labelled by the button itself.
   * @default <CircularProgress color="inherit" size={16} />
   */
  loadingIndicator?: ReactNode
  /**
   * The loading indicator can be positioned on the start, end, or the center of the button.
   * @default 'center'
   */
  loadingPosition?: "start" | "end" | "center"
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   */
}

export const Button = forwardRef<HTMLButtonElement, LoadingButtonProps>(
  (props, ref) => <MuiLoadingButton {...props} ref={ref} />,
)
Button.displayName = "Button"

export const ButtonGroup = forwardRef<HTMLDivElement, ButtonGroupProps>(
  (props, ref) => <MuiButtonGroup {...props} ref={ref} />,
)
ButtonGroup.displayName = "ButtonGroup"
