"use client"

import {
  Stack as MuiStack,
  type StackProps as MuiStackProps,
} from "@mui/material"
import { forwardRef } from "react"

export const Stack = forwardRef<HTMLDivElement, MuiStackProps>((props, ref) => (
  <MuiStack {...props} ref={ref} />
))

export type StackProps = MuiStackProps
Stack.displayName = "Stack"
