"use client"

import {
  Typography as MuiTypography,
  type TypographyProps,
} from "@mui/material"
import { forwardRef } from "react"

export const Typography = forwardRef<HTMLSpanElement, TypographyProps>(
  (props, ref) => <MuiTypography {...props} ref={ref} />,
)
Typography.displayName = "Typography"
